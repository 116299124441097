import { fetchAsObservable } from "fetcher!sofe";
import { reduce } from "lodash";

function urlBase() {
  return `/api/dashboard/resolution_cases`;
}

function lightweightUrlBase() {
  return `/api/resolution_case_dashboard`;
}

export function getResolutionCases(options = {}) {
  return fetchAsObservable(
    `${lightweightUrlBase()}${getQueryParams(options.queryParams)}`
  );
}

export function activateResolutionCases(ids) {
  return fetchAsObservable(
    `${urlBase()}?action=restore`,
    getCommonOptions(ids)
  );
}

export function deleteResolutionCases(ids) {
  return fetchAsObservable(`${urlBase()}?action=delete`, getCommonOptions(ids));
}

export function archiveResolutionCases(ids) {
  return fetchAsObservable(
    `${urlBase()}?action=archive`,
    getCommonOptions(ids)
  );
}

function getCommonOptions(ids) {
  return {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resolution_cases: {
        resolution_case_ids: ids,
      },
    }),
  };
}

function getQueryParams(queryParamsConfigOverrides = {}) {
  const queryParamsConfig = {
    users: 1,
    limit: 45,
    page: 0,
    sort: "due_date",
    ...queryParamsConfigOverrides,
  };
  return reduce(
    queryParamsConfig,
    (result, value, key) => {
      let and = result === "?" ? "" : "&";
      return `${result}${and}${key}=${value}`;
    },
    "?"
  );
}
