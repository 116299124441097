import React from "react";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";
import { reduce, merge } from "lodash";
import AsyncDecorator from "async-decorator/rx6";
import { NavContent } from "primary-navbar!sofe";
import { CpLoader } from "canopy-styleguide!sofe";
import SecondaryNavigationBar from "./secondary-navigation-bar";
import ResolutionCasesTable from "./resolution-cases-table";
import {
  getResolutionCases,
  activateResolutionCases,
  deleteResolutionCases,
  archiveResolutionCases,
} from "./dashboard-resolution-cases.resource";
import {
  getNavButtons,
  getColumnSortString,
  getSelectAllResolutionCasesObject,
} from "./dashboard.helper";

@AsyncDecorator
export default class Dashboard extends React.Component {
  state = {
    resolutionCases: [],
    currentPage: 0,
    loading: true,
    selectedResolutionCases: {},
    loadingNextBatch: false,
    showing: "active",
    allSelected: false,
    tableSort: {
      ascending: true,
      columnTitle: "Due Date",
    },
  };

  componentDidMount() {
    window.dashboardComp = this;
    this.fetchResolutionCases();
  }

  filterSelect = (item = {}) => {
    const config = item.id
      ? {
          queryParams: {
            client_id: item.id,
          },
        }
      : undefined;
    this.fetchResolutionCases(config);
  };

  fetchResolutionCases = (overrideConfig = {}) => {
    const fetchConfig = merge({}, this.getFetchConfig(), overrideConfig);
    this.setState({ loading: true, currentPage: 0 }, () => {
      this.props.cancelWhenUnmounted(
        getResolutionCases(fetchConfig).subscribe(
          (result) => {
            this.setState(
              (prev) => {
                return {
                  resolutionCases: result.resolution_cases,
                  loading: false,
                  allSelected: false,
                  anotherPageExists: result.more,
                  selectedResolutionCases: {},
                };
              },
              () => {
                this.maybeGetMoreResolutionCases(fetchConfig);
              }
            );
          },
          asyncStacktrace((err) => {
            this.setState({ loading: false });
            catchSyncStacktrace(err);
          })
        )
      );
    });
  };

  getFetchConfig = () => {
    const returnConfig = this.getStateQueryParams();
    returnConfig.queryParams.sort = getColumnSortString(this.state.tableSort);
    return returnConfig;
  };

  archive = () => {
    const arrayOfKeysToActivate = this.getIdsOfSelected();
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      archiveResolutionCases(arrayOfKeysToActivate).subscribe(
        () => {
          this.fetchResolutionCases();
        },
        asyncStacktrace((err) => {
          this.setState({ loading: false });
          catchSyncStacktrace(err);
        })
      )
    );
  };

  delete = () => {
    const arrayOfKeysToActivate = this.getIdsOfSelected();
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      deleteResolutionCases(arrayOfKeysToActivate).subscribe(
        () => {
          this.fetchResolutionCases();
        },
        asyncStacktrace((err) => {
          this.setState({ loading: false });
          catchSyncStacktrace(err);
        })
      )
    );
  };

  activate = () => {
    const arrayOfKeysToActivate = this.getIdsOfSelected();
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      activateResolutionCases(arrayOfKeysToActivate).subscribe(
        () => {
          this.fetchResolutionCases();
        },
        asyncStacktrace((err) => {
          this.setState({ loading: false });
          catchSyncStacktrace(err);
        })
      )
    );
  };

  setShowingThenFetch = (showingString) => {
    this.setState({ showing: showingString }, () => {
      this.fetchResolutionCases();
    });
  };

  maybeGetMoreResolutionCases = (config) => {
    const bodyIsNotScrollable =
      document.body.clientHeight < document.body.scrollHeight;
    if (this.state.anotherPageExists && bodyIsNotScrollable) {
      this.getNextPage(true);
    }
  };

  updateSort = (columnTitle) => {
    this.setState(
      (prevState) => {
        const newTableSort = { ...prevState.tableSort, columnTitle };
        if (newTableSort.columnTitle === prevState.tableSort.columnTitle) {
          newTableSort.ascending = !newTableSort.ascending;
        } else {
          newTableSort.ascending = true;
        }
        return {
          tableSort: newTableSort,
        };
      },
      () => this.fetchResolutionCases()
    );
  };

  getNextPage = (callAgain) => {
    this.setState({ loadingNextBatch: true });
    const stateQueryParams = this.getStateQueryParams();
    const config = merge(
      {},
      {
        queryParams: {
          page: this.state.currentPage + 1,
          sort: getColumnSortString(this.state.tableSort),
        },
      },
      stateQueryParams
    );
    this.props.cancelWhenUnmounted(
      getResolutionCases(config).subscribe(
        (result) => {
          this.setState(
            (prev) => {
              const mergedArray = []
                .concat(prev.resolutionCases)
                .concat(result.resolution_cases);
              let newSelectedResolutionCases = {};
              if (prev.allSelected) {
                newSelectedResolutionCases = getSelectAllResolutionCasesObject(
                  result.resolution_cases
                );
              }
              const myNewSelectedResolutionCases = merge(
                {},
                prev.selectedResolutionCases,
                newSelectedResolutionCases
              );
              return {
                resolutionCases: mergedArray,
                currentPage: config.queryParams.page,
                anotherPageExists: result.more,
                selectedResolutionCases: myNewSelectedResolutionCases,
                loadingNextBatch: false,
              };
            },
            () => {
              if (callAgain) {
                this.maybeGetMoreResolutionCases();
              }
            }
          );
        },
        asyncStacktrace((err) => {
          this.setState({ loading: false });
          catchSyncStacktrace(err);
        })
      )
    );
  };

  getStateQueryParams = () => {
    if (this.state.showing === "archived") {
      return { queryParams: { only: "archived" } };
    } else if (this.state.showing === "deleted") {
      return { queryParams: { only: "trashed" } };
    } else {
      return { queryParams: {} };
    }
  };

  selectResolutionCase = (resolutionCase) => {
    this.setState((prevState) => {
      const newSelected = { ...prevState.selectedResolutionCases };
      newSelected[resolutionCase.id] = true;
      return {
        selectedResolutionCases: newSelected,
      };
    });
  };

  deselectResolutionCase = (resolutionCase) => {
    this.setState((prevState) => {
      const newSelected = { ...prevState.selectedResolutionCases };
      delete newSelected[resolutionCase.id];
      return {
        allSelected: false,
        selectedResolutionCases: newSelected,
      };
    });
  };

  selectAllResolutionCases = () => {
    this.setState((prevState) => ({
      allSelected: true,
      selectedResolutionCases: getSelectAllResolutionCasesObject(
        prevState.resolutionCases
      ),
    }));
  };

  deselectAllResolutionCases = () => {
    this.setState((prevState) => ({
      allSelected: false,
      selectedResolutionCases: {},
    }));
  };

  render() {
    const navigationButtons = getNavButtons(this);

    return (
      <NavContent
        hasTopnavSecondary={true}
        clientMenuPossible={false}
        style={{ padding: 0 }}
      >
        <SecondaryNavigationBar
          selectedResolutionCases={this.state.selectedResolutionCases}
          iconConfig={navigationButtons}
        />
        {this.state.loading ? (
          <CpLoader size="lg" className="cp-p-24" />
        ) : (
          <ResolutionCasesTable
            showing={this.state.showing}
            sortOptions={this.state.tableSort}
            updateSort={this.updateSort}
            allSelected={this.state.allSelected}
            deselectResolutionCase={this.deselectResolutionCase}
            selectResolutionCase={this.selectResolutionCase}
            selectedResolutionCases={this.state.selectedResolutionCases}
            resolutionCases={this.state.resolutionCases}
            selectAllResolutionCases={this.selectAllResolutionCases}
            deselectAllResolutionCases={this.deselectAllResolutionCases}
            fetchingMore={this.state.loadingNextBatch}
            loadNextPage={this.getNextPage}
            anotherPageExists={this.state.anotherPageExists}
          />
        )}
      </NavContent>
    );
  }

  getIdsOfSelected = () => {
    return reduce(
      this.state.selectedResolutionCases,
      (result, value, key) => {
        if (value) {
          result.push(key);
        }
        return result;
      },
      []
    );
  };
}
