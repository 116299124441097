import { pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";

export function getAttachments(clientId, pivotType, pivotId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/attachments?pivot_type=${pivotType}&pivot_id=${pivotId}`
  ).pipe(pluck("attachments"));
}

export function getAttachmentsForTenant(tenantId, pivotType, pivotId) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/attachments?pivot_type=${pivotType}&pivot_id=${pivotId}`
  ).pipe(pluck("attachments"));
}

export function deleteAttachmentForTenant(tenantId, attachmentId) {
  return fetchAsObservable(
    `/api/tenants/${tenantId}/attachments/${attachmentId}`,
    {
      method: "DELETE",
    }
  ).pipe(pluck("attachments"));
}

export function deleteAttachment(clientId, attachmentId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/attachments/${attachmentId}`,
    {
      method: "DELETE",
    }
  ).pipe(pluck("attachments"));
}

export function saveAttachments(
  targetPath,
  targetId,
  pivotType,
  pivotId,
  attachment,
  ancestorId,
  ancestorType
) {
  return fetchAsObservable(
    `/api/${targetPath}/${targetId}/attachments?pivot_id=${pivotId}&pivot_type=${pivotType}${
      ancestorId ? `&ancestor_id=${ancestorId}` : ""
    }${ancestorType ? `&ancestor_type=${ancestorType}` : ""}`,
    {
      method: "POST",
      body: { attachments: attachment },
    }
  )
    .pipe(pluck("attachments"))
    .toPromise();
}

export function reportFileUpload(folderId, file) {
  return fetchAsObservable(`/api/docs/folders/${folderId}/files`, {
    method: "POST",
    body: { file },
  })
    .pipe(pluck("file"))
    .toPromise();
}

export function getS3AttachmentTargetEndpoint(folderId) {
  return fetchAsObservable(`/api/docs/folders/${folderId}/s3`)
    .pipe(pluck("s3"))
    .toPromise();
}

export function patchAttachment(clientId, attachmentId, payload) {
  return fetchAsObservable(
    `/api/clients/${clientId}/attachments/${attachmentId}`,
    {
      method: "PATCH",
      body: payload,
    }
  ).pipe(pluck("attachments"));
}
