import { pluck } from "rxjs/operators";
import { fetchAsObservable } from "fetcher!sofe";

export function getComments(clientId, pivotType, pivotId) {
  return fetchAsObservable(
    `/api/clients/${clientId}/comments/?pivot_type=${pivotType}&pivot_id=${pivotId}`
  ).pipe(pluck("comments"));
}

export function postComment(clientId, pivotType, pivotId, comment) {
  return fetchAsObservable(
    `/api/clients/${clientId}/comments/?pivot_type=${pivotType}&pivot_id=${pivotId}`,
    {
      method: "POST",
      body: { comments: comment },
    }
  ).pipe(pluck("comments"));
}

export function deleteComment(clientId, commentId) {
  return fetchAsObservable(`/api/clients/${clientId}/comments/${commentId}`, {
    method: "DELETE",
  });
}

export function updateComment(clientId, commentId, comment) {
  return fetchAsObservable(`/api/clients/${clientId}/comments/${commentId}`, {
    method: "PUT",
    body: { comments: comment },
  }).pipe(pluck("comments"));
}
