import React from "react";
import { get } from "lodash";
import { fromEvent } from "rxjs";
import AsyncDecorator from "async-decorator/rx6";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";
import { CpLoader } from "canopy-styleguide!sofe";
import ResolutionCasesDetailView from "./resolution-case-detail-view";
import { getClientRequest } from "./clientRequest.resource";
import { getIndividualResolutionCase } from "./resolution-case.resource";
import styles from "./steps.styles.css";

@AsyncDecorator
export default class Steps extends React.PureComponent {
  state = {
    loadingDetails: false,
    resolutionCaseDetails: undefined,
    clientRequests: undefined,
  };

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(this.el, "click").subscribe(this.shouldTriggerUpdate),
      fromEvent(this.listEl, "click").subscribe(this.listClick)
    );
  }

  render() {
    const count = get(this.props.resolutionCase, this.props.accessor);
    return (
      <div>
        <span ref={(el) => (this.el = el)} className={`${styles.summaryValue}`}>
          {count}
        </span>
        <ul
          ref={(list) => {
            this.listEl = list;
          }}
          className={`cps-list-unstyled ${styles.absolute}`}
        >
          {this.props.showing === this.props.resolutionCase.id &&
            this.props.accessor === this.props.showingAccessor &&
            count !== 0 && (
              <li className="cps-dropdown cps-open">
                <ul className="cps-dropdown-menu cps-padding-0" role="menu">
                  {this.state.loadingDetails ? (
                    <CpLoader />
                  ) : (
                    !!this.state.resolutionCaseDetails && (
                      <ResolutionCasesDetailView
                        status={this.props.status}
                        clientRequests={this.state.clientRequests}
                        fullResolutionCase={this.state.resolutionCaseDetails}
                      />
                    )
                  )}
                </ul>
              </li>
            )}
        </ul>
      </div>
    );
  }

  listClick = (evt) => {
    evt.keepPopupOpen = true;
  };

  shouldTriggerUpdate = (evt) => {
    if (get(this.props.resolutionCase, this.props.accessor) !== 0) {
      this.triggerUpdate(evt);
    }
  };

  triggerUpdate = (evt) => {
    if (
      get(this.state.resolutionCaseDetails, "id") ===
        this.props.resolutionCase.id &&
      this.props.showing === this.props.resolutionCase.id
    ) {
      this.setState({ resolutionCaseDetails: undefined });
    } else {
      this.setState({ loadingDetails: true }, () => {
        const clientId = this.props.resolutionCase.client_id;
        const resolutionCaseId = this.props.resolutionCase.id;
        this.props.cancelWhenUnmounted(
          getIndividualResolutionCase({ clientId, resolutionCaseId }).subscribe(
            (result) => {
              this.setState({
                resolutionCaseDetails: result.resolution_cases,
                loadingDetails: false,
              });
            },
            asyncStacktrace((err) => {
              this.setState({ loadingDetails: false });
              catchSyncStacktrace(err);
            })
          ),
          getClientRequest({ clientId, resolutionCaseId }).subscribe(
            (result) => {
              this.setState({ clientRequests: result.client_requests });
            },
            asyncStacktrace((err) => {
              this.setState({ loadingDetails: false });
              catchSyncStacktrace(err);
            })
          )
        );
      });
    }
    evt.currentlyShowing = this.props.resolutionCase.id;
    evt.showingAccessor = this.props.accessor;
    this.props.updateDetailDropdown(evt);
  };
}
